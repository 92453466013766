/**
 * Created by nick on 2/4/2020.
 */
import PropTypes from "prop-types"
import React from "react"
import logo1 from "../img/positive_life_logo.png"
import logo2 from "../img/positive-health-logo.png"
import logo3 from "../img/positive-health-logo.png"
import logo4 from "../img/health_and_life_logo.png"
import doctorno from "../images/doctor_no.png"
import avocado from "../images/avocado_landing_page_image.png"

import middleImage2 from "../images/avocado_landing_page_image.png"
import middleImage3 from "../images/avocado_landing_page_image.png"
import middleImage4 from "../images/avocado_landing_page_image.png"
import middleImage1 from "../images/mid_image_1.png"

import bottomImage1 from "../images/island_photo_1.png"
import bottomImage2 from "../images/island_photo.png"
import bottomImage3 from "../images/island_photo.png"
import bottomImage4 from "../images/island_photo.png"


import landingimageone from "../images/landing_image_2.png"
import islandphoto from "../images/island_photo.png"

import SiteInfo from "./siteinformation"


const QuestionContent = ({ siteTitle }) => (
    <section className="container question-content">
        <div className="content-header has-text-centered"><span >This quiz changed everything for me...</span></div>
        <div className="content-paragraph">

            I've known about low-carb diets since I was a kid in the 70s and my parents went on the low-carb "Atkins Diet," but I've avoided them because I love carbs (who doesn’t?). I started to investigate the role of sugar in hindering body metabolism.
            One day on social media I came across a quiz. It lead me to a discovery by a researcher named Norman Hearst. His research drove home several important points between the role of carbs, blood sugar, and body fluctuations.
        </div>
        <div className="content-paragraph">
            For one, Morgan gives us a narrative to show that doctors were making the claim that too many carbs led to obesity as early as the 19th Century. These claims were eclipsed by the non-scientific "Eat Low-Fat Foods", Watch Your Calories Diet, which Morgan shows does not work.
        </div>
        <div className="container content-image">

            <img src={getMiddleImage()} />
        </div>
        <div className="content-paragraph">
            No amount of willpower can fulfill the expectations of a low-fat, low-calorie diet. Carbohydrates high on the Glycemic Index can stimulate high blood sugar levels. This may result in two horrible things: Leptin Resistance and constant hunger.
            His solution does not offer extensive details for daily amount of carbs. There are no detailed menu plans. It’s not about the latest fads such as Keto. For strict "orthodox" ketogenic, low-carb champions, explain the amount is usually a mere 20-50 for the "first phase". This is followed by a maintenance level between 75-100 grams. However, other plans say one can eat as many as 150 "good" carbs a day, or even more for some. Why does one work? The other doesn’t?
            Is it because they all have not discovered the root cause of additional weight?
        </div>
        <div className="container content-image">

            <img src={getBottomImage()} />
        </div>

        <div className="content-paragraph">
            On a tiny island in Malaysia, a discovery revealed the contradiction with a lot of nutritional advice I've heard over the decades.
        </div>
        <div className="content-paragraph">
            We’ve observed that the low-carb craze of 20 years ago disappear. This is not because low-carb diets don't work. Instead, the snack industry got involved and created all sorts of low-carb snacks. This includes chips, protein bars, and other snack foods. Why? The reason is simple, it’s for making profits.
        </div>
        <div className="content-paragraph">
            By focusing on the role of EDCs, Morgan has made me very mindful of hidden connection between it and my body.
            This is why taking this quiz changed everything for the better. I normally would not respond to these online recommendations. But I’m sure happy I did!        </div>
        <div className="content-paragraph">
            Perhaps now it is your turn to discover what helped me.
        </div>
        <div className="content-paragraph">
            If you are under 35, it’s probably a waste of your time. But, for the rest of us, I recommend you go back up and take the 4 question quiz.
        </div>
        <div className="content-paragraph has-text-centered">
            <a href="#" className="button is-large click-here-button">Click Here To Take The Quiz!</a>
        </div>

        <div className="content-header has-text-centered">Have you taken the quiz and seen your results yet?</div>
        <div className="content-header has-text-centered">If not, scroll up to the top and start the quick but surprisingly accurate quiz now!</div>

    </section>

  )




function getSiteNumber() {
    var site = new SiteInfo().getSiteNumber();
    return site;
}


function getMiddleImage() {
    var site = new SiteInfo().getSiteNumber();

    if (site == 2) {
        return middleImage2;
    }
    if (site == 3) {
        return middleImage3;
    }
    if (site == 4) {
        return middleImage4;
    }
    return middleImage1;
}


function getBottomImage() {
    var site = new SiteInfo().getSiteNumber();

    if (site == 2) {
        return bottomImage2;
    }
    if (site == 3) {
        return bottomImage3;
    }
    if (site == 4) {
        return bottomImage4;
    }
    return bottomImage1;
}


QuestionContent.propTypes = {
    siteTitle: PropTypes.string,
}

QuestionContent.defaultProps = {
    siteTitle: ``,
}

export default QuestionContent
