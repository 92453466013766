/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, StaticQuery } from "gatsby"
import footerbg from "../img/footer-bg.jpg"
import BackgroundImage from 'gatsby-background-image'

import Header from "./header"
import QuestionFooter from "./questionfooter"
import QuestionContent from "./questioncontent"

/*import "../css/animate.css"
import "../css/bootstrap.min.css"
import "../css/font-awesome.min.css"
import "../css/owl.carousel.css"*/


import "bulma/bulma.sass"
import "bulma/sass/utilities/_all.sass"

import SiteInfo from './siteinformation'

import questionImage1 from "../images/adimage1.png";
import questionImage2 from "../images/LP_landing_page_image.png";
import questionImage3 from "../images/LP_landing_page_image.png";
import questionImage4 from "../images/landing_image_1.png";
import questionImage5 from "../images/LP_landing_page_image.png";

 import  "../css/questions.scss"
import Helmet from 'react-helmet'


var title = "Test";
title = getTitle();

const QuestionLayout = ({ children, subquestion, nocontent }) => {

  return (
    <div className="question-container">
      <Helmet>
        <link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Open+Sans" />
          <script src='/utilities.js' type="text/javascript" />
          {
              (getIsDevelopment()) &&
              <script src='/devsite.js' type="text/javascript" />
          }
          { (getSite() == 1 && getIsNotDevelopment()) &&
          <script src='/positivelifesite.js' type="text/javascript" />
          }
          { (getSite() == 4 && getIsNotDevelopment()) &&
          <script src='/healthandlife_pixel.js' type="text/javascript" />
          }
      </Helmet>

    <header
        className={getHeaderClasses()}>

        <div className="header-columns columns is-centered">


            <div className="column has-text-centered">
                <div className="title-1-text">Can Anyone Get Lean?
{/*
                    This <b>"Unexpected 5-Second Water Hack"</b> that 92% of People Have No
                    Idea Exists...*/}
                </div>
                {/*<div className="title-2-text">Let's see if this could work for you by taking this quiz.</div>*/}
                <div className="title-2-text">92% of people have no idea this method exists.</div>
            </div>

        </div>

    </header>

    <section className="container">
        <div className="quiz-level">
        <div className="columns">
            <div className="column is-half is-vcentered">
                <div className="main-question-div-container">
      <main>{children}</main>
                </div>
            </div>
            <div className="column is-half quiz-image-container">
                <div>
                   <img className="quiz-layout-top-image" src={getQuestionImage()} />
                </div>
            </div>
        </div>
        </div>
    </section>

    <QuestionContent></QuestionContent>
    <QuestionFooter className="question-footer">

    </QuestionFooter>



    </div>
  )
}

QuestionLayout.propTypes = {
  children: PropTypes.node.isRequired,
}


function getTitle() {
  const site = process.env.GATSBY_SITE;
  if (site == 'positivelife.link') {
    return "Positive Life Link";
  }
  if (site == 'positivehealth.link') {
    return "Positive Health Link";
  }
}

function getSite() {
    var site = new SiteInfo().getSiteNumber();
    return site;
}

function getIsDevelopment() {
    return new SiteInfo().getIsDevelopment();
}

function getIsNotDevelopment() {
    return !(new SiteInfo().getIsDevelopment());
}


export default QuestionLayout

function getHeaderClasses() {
    var site = new SiteInfo().getSiteNumber();

    if (site == 2) {
        return "container is-fullwidth-mobile is-fullwidth-tablet header-section-question question-background-1 is-vertical-center";
    }
    if (site == 3) {
        return "container is-fullwidth-mobile is-fullwidth-tablet header-section-question question-background-1 is-vertical-center";
    }
    if (site == 4) {
        return "container is-fullwidth-mobile is-fullwidth-tablet header-section-question question-background-1 is-vertical-center";
    }


    return "container is-fullwidth-mobile is-fullwidth-tablet header-section-question question-background-1 is-vertical-center";
}

function getQuestionImage() {


        var site = new SiteInfo().getSiteNumber();

        if (site == 2) {
            return questionImage2;
        }
        if (site == 3) {
            return questionImage3;
        }
        if (site == 4) {
            return questionImage4;
        }


    return questionImage1;
}


function getOutboundSiteURL() {
    const site = process.env.GATSBY_SITE;
    return "https://hop.clickbank.net/?affiliate=nafpliotis&vendor=leptitox&h3=y&na=y&np=y";
}
