import React from 'react'
import {graphql, StaticQuery} from 'gatsby'
import styled from 'styled-components'
import logo1 from "../img/positive_life_logo.png"
import logo2 from "../img/positive-health-logo.png"
import logo3 from "../img/positive-health-logo.png"
import logo4 from "../img/health_and_life_logo.png"
import BackgroundImage from 'gatsby-background-image'

import footerBackgroundImage from '../images/LP_landing_page_image.png'
import SiteInfo from "./siteinformation"

const QuestionFooter = ({className}) => {

    return (

        <div className="container">
        <footer className="footer-section question-footer question-background-1">
            <div className="container-fluid">
                <div className="row">

                    <div className="col-lg-12 text-lg-right">
                        <ul className="footer-menu color-white">
                            <li><a href="/">Home</a></li>
                            <li><a href="/earnings/">Earnings</a></li>
                            <li><a href="/privacy/">Privacy</a></li>
                            <li><a href="/terms/">Terms and Conditions</a></li>
                            <li><a href="/dmca/">DMCA</a></li>
                            <li><a href="mailto:admin@healthandlife.link">Contact</a></li>
                        </ul>
                        <div>
                            This site is not a part of the Facebook website or Facebook Inc.
                            Additionally, this site is NOT endorsed by Facebook in any way.
                            FACEBOOK is a trademark of FACEBOOK, Inc.
                        </div>
                        <p
                            className="copyright">
                            Copyright &copy;2020&nbsp;
                            <script>document.write(new Date().getFullYear());</script>
                            All rights reserved | This template is made with <i className="fa fa-heart-o"
                                                                                aria-hidden="true"></i> by <a
                            href="https://colorlib.com" target="_blank">Colorlib</a>

                        </p>
                    </div>
                </div>
            </div>
        </footer>
        </div>)
}

function getHeaderLogo() {
    var site = new SiteInfo().getSiteNumber();

    if (site == 2) {
        return logo2;
    }
    if (site == 3) {
        return logo3;
    }
    if (site == 4) {
        return logo4;
    }

    return logo1;
}

export default QuestionFooter



